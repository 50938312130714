import { FilterList } from "@mui/icons-material";
import {
  Box,
  Button,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Modal,
  Stack,
  CardActions,
  CardActionArea,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Display } from "./Displays";

interface UploadMediaRequest {
  files: FileList | undefined;
}

interface File {
  uuid: string;
  name: string;
  size: number;
  displays: Display[];
  created: string;
  updated: string;
}

const bytesToSize = (bytes: number): string => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
    10
  );
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

const Medien = () => {
  const [addMediaModal, setAddMediaModal] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [searchMedia, setSearchMedia] = useState<string>("");
  const [filterDisplays, setFilterDisplays] = useState<Display[]>([]);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const filesFilerOpen = Boolean(filterAnchorEl);
  const [displays, setDisplays] = useState<Display[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    undefined
  );

  const handleFilterButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterItemClick = (d: Display) => {
    const copy = [...filterDisplays];

    for (let i = 0; i < copy.length; i++) {
      if (copy[i].uuid === d.uuid) {
        copy.splice(i, 1);
        setFilterDisplays(copy);
        return;
      }
    }

    copy.push(d);
    setFilterDisplays(copy);
  };

  const handleDeleteMedia = (file: File) => {
    axios
      .delete("/files/" + file.uuid)
      .then(() => {
        const copy = [...files];
        const imagesCopy = [...images];
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].uuid === file.uuid) {
            copy.splice(i, 1);
            imagesCopy.splice(i, 1);
            break;
          }
        }
        setFiles(copy);
        setImages(imagesCopy);
      })
      .catch((error) => console.log(error.response.data));
  };

  const UploadMediaForm = () => {
    const { register, handleSubmit, watch } = useForm<UploadMediaRequest>();
    const __files = watch("files");

    const submit = (data: UploadMediaRequest) => {
      if (data.files && data.files[0]) {
        const formData = new FormData();
        formData.append("file", data.files[0]);
        axios
          .post("/files", formData, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then(async (response) => {
            const copy = [...files];
            copy.push(response.data);
            setFiles(copy);
            setAddMediaModal(false);
          })
          .catch((error) => console.log(error.response.data));
      }
    };

    return (
      <Stack
        spacing={2}
        sx={{ mt: 3 }}
        component="form"
        onSubmit={handleSubmit(submit)}
      >
        {__files && __files[0] && (
          <Box>
            <Typography gutterBottom variant="body2" component="div">
              Ausgewählt: <b>{__files[0].name}</b>
            </Typography>
            <Typography gutterBottom variant="body2" component="div">
              Größe: {bytesToSize(__files[0].size)}
            </Typography>
          </Box>
        )}
        <Button fullWidth component="label">
          Datei auswählen
          <input
            accept="application/pdf,image/jpeg,image/gif,image/png"
            {...register("files")}
            type="file"
            hidden
          />
        </Button>
        <Button fullWidth type="submit" color="success" variant="contained">
          Hochladen
        </Button>
      </Stack>
    );
  };

  const getFileImage = (name: string | null): Promise<string> =>
    axios
      .get("/files/" + name + "/buffer", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        let img = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        return `data:${response.headers[
          "content-type"
        ].toLowerCase()};base64,${img}`;
      });

  const handleSearchMediaOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchMedia(event.currentTarget.value);
  };

  const handleActionAreaClick = (index: number) => {
    setSelectedImage(images[index]);
  };

  useEffect(() => {
    let cancel = false;

    axios
      .get<Display[]>("/displays")
      .then((response) => {
        if (!cancel) {
          setDisplays(response.data);
        }
      })
      .catch((error) => console.log(error));

    axios
      .get<File[]>("/files")
      .then(async (response) => {
        if (!cancel) {
          setFiles(response.data);
        }
      })
      .catch((error) => console.log(error.response.data));

    return () => {
      cancel = true;
    };
  }, []);

  useEffect(() => {
    let cancel = false;

    const promises = [];

    for (let i = 0; i < files.length; i++) {
      promises.push(getFileImage(files[i].name));
    }

    Promise.all(promises).then((values) => {
      if (values && !cancel) {
        setImages(values);
      }
    });

    return () => {
      cancel = true;
    };
  }, [files]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Button size="large" onClick={() => setAddMediaModal(true)}>
          Medien hinzufügen
        </Button>
        <TextField
          type="search"
          sx={{ ml: 1 }}
          size="small"
          placeholder="Suchen..."
          value={searchMedia}
          onChange={handleSearchMediaOnChange}
        />
        <IconButton
          sx={{ ml: "auto" }}
          id="search-files-filter-button"
          aria-controls={
            filesFilerOpen ? "search-files-filter-menu" : undefined
          }
          aria-haspopup="true"
          aria-expanded={filesFilerOpen ? "true" : undefined}
          onClick={handleFilterButtonClick}
        >
          <FilterList />
        </IconButton>
        <Menu
          id="search-files-filter-menu"
          aria-labelledby="search-files-filter-button"
          anchorEl={filterAnchorEl}
          open={filesFilerOpen}
          onClose={() => setFilterAnchorEl(null)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {displays.map((display, index) => (
            <MenuItem
              sx={{ minWidth: 300 }}
              key={index}
              onClick={() => handleFilterItemClick(display)}
            >
              <Checkbox checked={filterDisplays.includes(display)} />
              {display.title}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Grid container spacing={2}>
        {files
          .filter((file) => {
            if (!filterDisplays.length) return true;
            for (let d of file.displays) {
              if (filterDisplays.find((display) => (display.uuid = d.uuid))) {
                return true;
              }
            }
            return false;
          })
          .filter((file) => {
            if (!searchMedia.length) return true;
            const s = searchMedia.toLowerCase();
            const name = file.name.toLowerCase();
            return name.indexOf(s) > -1;
          })
          .map((file, index) => (
            <Grid item sm={12} md={6} lg={4} key={index}>
              <Card>
                <CardActionArea onClick={() => handleActionAreaClick(index)}>
                  <CardMedia
                    component="img"
                    height="140"
                    alt={file.name}
                    image={images[index]}
                  />
                </CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {file.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {bytesToSize(file.size)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {file.displays.length} Displays
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="error"
                    type="button"
                    size="large"
                    onClick={() => handleDeleteMedia(file)}
                  >
                    löschen
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      <Modal open={addMediaModal} onClose={() => setAddMediaModal(false)}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Medien hochladen
              <UploadMediaForm />
            </Typography>
          </CardContent>
        </Card>
      </Modal>
      <Modal
        open={typeof selectedImage !== "undefined"}
        onClose={() => setSelectedImage(undefined)}
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
          }}
        >
          <div
            style={{
              height: "80vh",
              width: "100%",
              backgroundImage: "url(" + selectedImage + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default Medien;
