import { Paper, Tab, Tabs } from "@mui/material";
import de from "dayjs/locale/de";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Users from "./components/Users";
import Menus from "./components/Menus";
import Displays from "./components/Displays";
import Medien from "./components/Medien";
import { useAuth } from "./Auth";

dayjs.locale(de);

const Dashboard = () => {
  const auth = useAuth();
  const [tab, setTab] = useState<number>(0);
  const [items, setItems] = useState<React.ReactNode[] | null>(null);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const TabContent: React.FC = () => {
    const [items, setItems] = useState<React.ReactNode[] | null>(null);

    useEffect(() => {
      if (auth.user) {
        switch (auth.user.role) {
          case "administrator": {
            setItems([
              tab === 0 ? <Displays key={0} /> : null,
              tab === 1 ? <Menus key={1} /> : null,
              tab === 2 ? <Medien key={2} /> : null,
              tab === 3 ? <Users key={3} /> : null,
            ]);
            break;
          }
          case "gourmetta": {
            setItems([<Menus />]);
            break;
          }
          case "kronospan": {
            setItems([
              tab === 0 ? <Displays key={0} /> : null,
              tab === 1 ? <Medien key={1} /> : null,
            ]);
            break;
          }
          default: {
            break;
          }
        }
      }
    }, []);

    return <React.Fragment>{items}</React.Fragment>;
  };

  useEffect(() => {
    if (auth.user) {
      switch (auth.user.role) {
        case "administrator": {
          setItems([
            <Tab key={0} label="Displays" />,
            <Tab key={1} label="Menüs" />,
            <Tab key={2} label="Medien" />,
            <Tab key={3} label="Benutzer" />,
          ]);
          break;
        }
        case "gourmetta": {
          setItems([<Tab key={0} label="Menüs" />]);
          break;
        }
        case "kronospan": {
          setItems([
            <Tab key={0} label="Displays" />,
            <Tab key={1} label="Medien" />,
          ]);
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [auth.user]);

  return (
    <React.Fragment>
      <Paper square>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="tabs"
        >
          {items}
        </Tabs>
      </Paper>
      <TabContent />
    </React.Fragment>
  );
};

export default Dashboard;
