import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  CardActionArea,
  Skeleton,
  CardActions,
  Button,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Display as DisplayInterface } from "./Displays";

const Display: React.FC<{
  display: DisplayInterface;
  handleDisplayClick: () => void;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
  onDelete?: () => void;
}> = ({ display, handleDisplayClick, onError, onDelete }) => {
  const [preview, setPreview] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleDeleteClick = () => {
    axios
      .delete("/displays/" + display.uuid)
      .then(() => {
        if (typeof onDelete !== "undefined") {
          onDelete();
        }
      })
      .catch(() => {
        if (typeof onError !== "undefined") {
          onError("Fehler beim Löschen des Displays");
        }
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      setLoading(true);
    }
    axios
      .get<string>("/preview/" + display.uuid)
      .then((response) => {
        if (!cancel) {
          setPreview(response.data);
          setLoading(false);
        }
      })
      .catch((error) => console.log(error));

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      {loading && <Skeleton variant="rectangular" height={300} />}
      {!loading && (
        <CardActionArea onClick={() => {}}>
          <CardMedia
            component="img"
            height="300"
            sx={{ objectFit: "fill" }}
            alt={display.title}
            loading={"lazy"}
            src={preview}
          />
        </CardActionArea>
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {display.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Text: {display.text}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={handleDisplayClick} size="small">
          bearbeiten
        </Button>
        <Button onClick={handleDeleteClick} size="small" color="error">
          löschen
        </Button>
      </CardActions>
    </Card>
  );
};

export default Display;
