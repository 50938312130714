import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";
import { AuthProvider } from "./Auth";
import { BrowserRouter } from "react-router-dom";

axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3333/kronospan"
    : "https://api.gourmetta.de/kronospan";
axios.interceptors.request.use(
  (config) => {
    if (config.headers) {
      config.headers.authorization = `Bearer ${localStorage.getItem("token")}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
