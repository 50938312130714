import React, { useEffect, useState } from "react";
import { LocalizationProvider, StaticDatePicker, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  MenuItem,
  Modal,
  Select,
  FormControl,
  FormLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import de from "dayjs/locale/de";
import axios from "axios";
import { useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";

dayjs.locale(de);

export interface Meal {
  uuid: string;
  title: string;
  description: string;
  allergens: string;
  price: number;
  date: string;
  type: MealType;
}

export interface MealType {
  uuid: string;
  text: string;
  kurz: string;
  created: string;
  updated: string;
}

export const formatEuro = (price: number): string =>
  new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(price);

type CustomPickerDayProps = PickersDayProps<Date> & {
  hasMeal: boolean;
  isSelected: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "hasMeal" && prop !== "isSelected",
})<CustomPickerDayProps>(({ hasMeal, isSelected }) => ({
  ...(hasMeal && {
    "::after": {
      content: "''",
      position: "absolute",
      top: "75%",
      left: "50%",
      transform: "translateX(-50%)",
      width: "6px",
      height: "6px",
      backgroundColor: "green",
      borderRadius: 1000,
      ...(isSelected && {
        backgroundColor: "#fff",
      }),
    },
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const Menus = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [addMealModal, setAddMealModal] = useState<boolean>(false);
  const [meals, setMeals] = useState<Meal[]>([]);
  const [mealTypes, setMealTypes] = useState<MealType[]>([]);
  const [editMealModal, setEditMealModal] = useState<boolean>(false);
  const [meal, setMeal] = useState<Meal | null>(null);

  useEffect(() => {
    let cancel = false;

    axios
      .get<Meal[]>("/meals?month=" + dayjs().month())
      .then((response) => {
        if (!cancel) {
          setMeals(response.data);
        }
      })
      .catch((error) => console.log(error));
    axios
      .get<MealType[]>("/mealtypes")
      .then((response) => {
        if (!cancel) {
          setMealTypes(response.data);
        }
      })
      .catch((error) => console.log(error));

    return () => {
      cancel = true;
    };
  }, []);

  const handleDeleteMeal = (meal: Meal) => {
    axios
      .delete("/meals/" + meal.uuid)
      .then(() => {
        const copy = [...meals];
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].uuid === meal.uuid) {
            copy.splice(i, 1);
          }
        }
        setMeals(copy);
      })
      .catch((error) => console.log(error));
  };

  const InsertForm: React.FC = () => {
    const { register, handleSubmit, watch, setValue } = useForm<Meal>();

    const currentUuid = watch("type");

    const handleTypeChange = (event: any) => {
      for (let i = 0; i < mealTypes.length; i++) {
        if (mealTypes[i].uuid === event.target.value) {
          setValue("type", mealTypes[i]);
        }
      }
    };

    const submit = (data: Meal) => {
      if (selectedDate) {
        data.date = dayjs(selectedDate).format("YYYY-MM-DD");

        axios
          .post<Meal>("/meals", data)
          .then((response) => {
            const copy = [...meals];
            copy.push(response.data);
            setMeals(copy);
            setAddMealModal(false);
          })
          .catch((error) => console.log(error));
      }
    };

    return (
      <Box component="form" onSubmit={handleSubmit(submit)}>
        <FormControl fullWidth>
          <FormLabel id="label-menu-select">Menü</FormLabel>
          <Select
            required
            fullWidth
            labelId="label-menu-select"
            value={currentUuid ? currentUuid.uuid : ""}
            onChange={handleTypeChange}
          >
            {mealTypes.map((type, index) => (
              <MenuItem
                disabled={Boolean(
                  meals
                    .filter((meal) => dayjs(meal.date).isSame(dayjs(selectedDate), "day"))
                    .find((meal) => meal.type.uuid === type.uuid)
                )}
                key={index}
                value={type.uuid}
              >
                {type.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField margin="normal" required fullWidth {...register("title")} label="Titel" />
        <TextField
          margin="normal"
          required
          fullWidth
          {...register("description")}
          label="Beschreibung"
          multiline
          rows={2}
        />
        <TextField margin="normal" required fullWidth {...register("allergens")} label="Allergene" />
        <TextField
          margin="normal"
          required
          fullWidth
          type="text"
          label="Preis"
          {...register("price", {
            setValueAs: (value: any) => parseFloat(value.toString().replace(/,/g, ".")),
          })}
          inputProps={{ inputMode: "numeric" }}
        />
        <Button type="submit" size="large" fullWidth variant="contained">
          Menü speichern
        </Button>
      </Box>
    );
  };

  const UpdateForm: React.FC = () => {
    const { register, handleSubmit } = useForm<Meal>({
      defaultValues: meal ? meal : undefined,
    });
    const submit = (data: Meal) => {
      axios.patch<Meal>("/meals", data).then((response) => {
        setEditMealModal(false);
        setMeal(null);
        const copy = [...meals];
        for (let i = 0; i < copy.length; i++) {
          if (copy[i].uuid === response.data.uuid) {
            copy[i] = response.data;
            break;
          }
        }
        setMeals(copy);
      });
    };
    return (
      <Box component="form" onSubmit={handleSubmit(submit)}>
        <TextField margin="normal" required fullWidth {...register("title")} label="Titel" />
        <TextField margin="normal" required fullWidth {...register("description")} label="Beschreibung" />
        <TextField margin="normal" required fullWidth {...register("allergens")} label="Allergene" />
        <TextField
          margin="normal"
          required
          fullWidth
          type="text"
          label="Preis"
          {...register("price", {
            setValueAs: (value: any) => parseFloat(value.toString().replace(/,/g, ".")),
          })}
          inputProps={{ inputMode: "numeric" }}
        />
        <Button type="submit" size="large" fullWidth variant="contained">
          Menü speichern
        </Button>
      </Box>
    );
  };

  const dayGotMeals = (date: Date): boolean => {
    if (meals.length <= 0) return false;
    for (let i = 0; i < meals.length; i++) {
      if (dayjs(meals[i].date).isSame(dayjs(date), "day")) return true;
    }
    return false;
  };

  const renderWeekPickersDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickerDayProps: PickersDayProps<Date>
  ) => {
    const dayIsSelected = dayjs(selectedDate).isSame(date, "day");

    if (!date || !meals.length) {
      return <PickersDay {...pickerDayProps} />;
    }

    return <CustomPickersDay {...pickerDayProps} isSelected={dayIsSelected} hasMeal={dayGotMeals(date)} />;
  };

  const handleMonthChange = (date: Date | null) => {
    axios
      .get<Meal[]>("/meals?month=" + dayjs(date).month())
      .then((response) => {
        setMeals(response.data);
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
      >
        <Button onClick={() => setAddMealModal(true)} size="large">
          Menü erstellen
        </Button>
      </Box>
      <Stack spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={de}>
          <StaticDatePicker<Date>
            orientation="landscape"
            label="Datum wählen"
            disablePast={true}
            openTo="day"
            value={selectedDate}
            onMonthChange={handleMonthChange}
            renderDay={renderWeekPickersDay}
            onChange={(date: Date | null) => setSelectedDate(date)}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Menü</TableCell>
                <TableCell>Titel / Beschreibung</TableCell>
                <TableCell>Allergene</TableCell>
                <TableCell>Preis</TableCell>
                <TableCell align="right">Aktion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {meals
                .filter((meal) => dayjs(meal.date).isSame(selectedDate, "day"))
                .sort((a, b) => {
                  if (a.type.kurz < b.type.kurz) return -1;
                  if (a.type.kurz > b.type.kurz) return 1;
                  return 0;
                })
                .map((meal, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                        {meal.type.kurz}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                        {meal.title}
                      </Typography>
                      <Typography variant="body2">{meal.description}</Typography>
                    </TableCell>
                    <TableCell>{meal.allergens}</TableCell>
                    <TableCell>{formatEuro(meal.price)}</TableCell>
                    <TableCell>
                      <Stack direction="row" justifyContent="right" spacing={2}>
                        <Button onClick={() => handleDeleteMeal(meal)} size="medium" color="error">
                          Löschen
                        </Button>
                        <Button
                          onClick={() => {
                            setMeal(meal);
                            setEditMealModal(true);
                          }}
                          size="medium"
                          color="primary"
                        >
                          Bearbeiten
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Modal open={addMealModal} onClose={() => setAddMealModal(false)}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Menü erstellen
            </Typography>
            <InsertForm />
          </CardContent>
        </Card>
      </Modal>
      <Modal
        open={editMealModal}
        onClose={() => {
          setEditMealModal(false);
          setMeal(null);
        }}
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {meal ? meal.type.kurz : ""} bearbeiten
            </Typography>
            <UpdateForm />
          </CardContent>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default Menus;
