import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Card,
  CardActionArea,
  Modal,
  Box,
  CardContent,
  Typography,
  TextField,
  Stack,
  Checkbox,
  FormControlLabel,
  Chip,
  CardActions,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useAuth } from "../Auth";

export interface User {
  uuid: string;
  email: string;
  vorname: string;
  nachname: string;
  disabled: boolean;
  created: string;
  updated: string;
  role: "administrator" | "gourmetta" | "kronospan";
}

interface CreateUser extends User {
  password: string;
  passwordConfirm: string;
}

const Users = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [addUserModal, setAddUserModal] = useState<boolean>(false);
  const [editUserModal, setEditUserModal] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [deleteQuestionModal, setDeleteQuestionModal] =
    useState<boolean>(false);
  const auth = useAuth();

  useEffect(() => {
    let cancel = false;

    axios
      .get<User[]>("/users")
      .then((response) => {
        if (!cancel) {
          setUsers(response.data);
        }
      })
      .catch((error) => console.log(error.response.data));

    return () => {
      cancel = true;
    };
  }, []);

  const UpdateUser = () => {
    const { register, handleSubmit } = useForm<CreateUser>({
      defaultValues: {
        ...(user ? user : undefined),
        password: undefined,
        passwordConfirm: undefined,
      },
    });

    const submit = (data: CreateUser) => {
      axios
        .patch<User>("/users", data)
        .then((response) => {
          const copy = [...users];
          for (let i = 0; i < copy.length; i++) {
            if (copy[i].uuid === response.data.uuid) {
              copy[i] = response.data;
            }
          }
          setUsers(copy);
          setEditUserModal(false);
        })
        .catch((error) => console.log(error.response.data));
    };

    return (
      <Stack spacing={2} component="form" onSubmit={handleSubmit(submit)}>
        <TextField
          {...register("email")}
          label="E-Mail"
          required
          type="email"
        />
        <TextField
          {...register("vorname")}
          label="Vorname"
          required
          type="text"
        />
        <TextField
          {...register("nachname")}
          label="Nachname"
          required
          type="text"
        />
        <FormControl fullWidth>
          <InputLabel id="role-label">Rolle</InputLabel>
          <Select
            labelId="role-label"
            input={<OutlinedInput {...register("role")} label="Rolle" />}
            defaultValue={user ? user.role : ""}
          >
            <MenuItem disabled value={""}>
              Auswählen
            </MenuItem>
            <MenuItem value={"gourmetta"}>Gourmetta</MenuItem>
            <MenuItem value={"kronospan"}>Kronospan</MenuItem>
            <MenuItem value={"administrator"}>Administrator</MenuItem>
          </Select>
        </FormControl>
        <TextField
          {...register("password", {
            minLength: 8,
            maxLength: 50,
          })}
          type="password"
          label="Passwort"
        />
        <TextField
          {...register("passwordConfirm", {
            minLength: 8,
            maxLength: 50,
          })}
          type="password"
          label="Passwort bestätigen"
        />
        <FormControlLabel
          control={
            <Checkbox
              {...register("disabled")}
              defaultChecked={user?.disabled}
            />
          }
          label="Login deaktiviert?"
        />
        <Button fullWidth size="large" type="submit">
          Benutzer aktualisieren
        </Button>
        <Button
          fullWidth
          size="large"
          type="button"
          color="error"
          onClick={() => setDeleteQuestionModal(true)}
        >
          Benutzer löschen
        </Button>
      </Stack>
    );
  };

  const InsertUser = () => {
    const { register, handleSubmit } = useForm<CreateUser>();

    const submit = (data: CreateUser) => {
      if (data.password) {
        if (data.password !== data.passwordConfirm) {
          return;
        }
      }

      axios
        .post<User>("/users", data)
        .then((response) => {
          const copy = [...users];
          copy.push(response.data);
          setUsers(copy);
          setAddUserModal(false);
        })
        .catch((error) => console.log(error.response.data));
    };

    return (
      <Stack spacing={2} component="form" onSubmit={handleSubmit(submit)}>
        <TextField
          {...register("email")}
          label="E-Mail"
          required
          type="email"
        />
        <TextField
          {...register("vorname")}
          label="Vorname"
          required
          type="text"
        />
        <TextField
          {...register("nachname")}
          label="Nachname"
          required
          type="text"
        />
        <FormControl fullWidth>
          <InputLabel id="role-label">Rolle</InputLabel>
          <Select
            labelId="role-label"
            input={<OutlinedInput {...register("role")} label="Rolle" />}
            defaultValue={user ? user.role : ""}
          >
            <MenuItem disabled value={""}>
              Auswählen
            </MenuItem>
            <MenuItem value={"gourmetta"}>Gourmetta</MenuItem>
            <MenuItem value={"kronospan"}>Kronospan</MenuItem>
            <MenuItem value={"administrator"}>Administrator</MenuItem>
          </Select>
        </FormControl>
        <TextField
          {...register("password", { minLength: 8, maxLength: 50 })}
          type="password"
          label="Passwort"
        />
        <TextField
          {...register("passwordConfirm", { minLength: 8, maxLength: 50 })}
          type="password"
          label="Passwort bestätigen"
        />
        <FormControlLabel
          control={<Checkbox {...register("disabled")} />}
          label="Login deaktiviert?"
        />
        <Button fullWidth size="large" type="submit">
          Benutzer erstellen
        </Button>
      </Stack>
    );
  };

  const handleCardClick = (user: User) => {
    if (auth.user && auth.user.role === "administrator") {
      setUser(user);
      setEditUserModal(true);
    } else {
      if (user.email === "admin@gourmetta.de") return;
      setUser(user);
      setEditUserModal(true);
    }
  };

  const handleEditModalClose = () => {
    setEditUserModal(false);
    setUser(null);
  };

  const handleDeleteUser = () => {
    if (user) {
      axios
        .delete("/users/" + user.uuid)
        .then(() => {
          const copy = [...users];
          for (let i = 0; i < copy.length; i++) {
            if (copy[i].uuid === user.uuid) {
              copy.splice(i, 1);
            }
          }
          setUsers(copy);
          setUser(null);
          setEditUserModal(false);
          setDeleteQuestionModal(false);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
      >
        <Button onClick={() => setAddUserModal(true)} size="large">
          Benutzer erstellen
        </Button>
      </Box>
      <Grid container spacing={2}>
        {users.map((user, index) => (
          <Grid item sm={12} md={4} lg={3} key={index}>
            <Card>
              <CardActionArea onClick={() => handleCardClick(user)}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {user.vorname} {user.nachname}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {user.email}
                  </Typography>
                </CardContent>
                <CardActions>
                  {user.disabled ? (
                    <Chip size="small" label="Inaktiv" color="error" />
                  ) : (
                    <Chip size="small" label="Aktiv" color="success" />
                  )}
                </CardActions>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal open={editUserModal} onClose={handleEditModalClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Benutzer bearbeiten
            </Typography>
            <UpdateUser />
          </CardContent>
        </Card>
      </Modal>
      <Modal open={addUserModal} onClose={() => setAddUserModal(false)}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Benutzer erstellen
            </Typography>
            <InsertUser />
          </CardContent>
        </Card>
      </Modal>
      <Modal
        open={deleteQuestionModal}
        onClose={() => setDeleteQuestionModal(false)}
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="body1" component="div">
              Soll der Benutzer wirklich gelöscht werden?
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              color="info"
              onClick={() => setDeleteQuestionModal(false)}
              sx={{ flexGrow: 1 }}
            >
              Abbrechen
            </Button>
            <Button
              color="error"
              onClick={handleDeleteUser}
              sx={{ flexGrow: 1 }}
            >
              Löschen
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default Users;
