import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Container,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import { useAuth } from "./Auth";
import { useForm } from "react-hook-form";

interface LoginRequest {
  email: string;
  password: string;
}

const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<LoginRequest>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const submit = (data: LoginRequest) => {
    setLoading(true);
    auth
      .login(data.email, data.password)
      .then(() => navigate("/"))
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <Container maxWidth="xs" sx={{ marginLeft: "auto", marginRight: "auto" }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography component="h1" variant="h5">
          Einloggen
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(submit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email Address"
            autoComplete="email"
            autoFocus
            {...register("email")}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            {...register("password")}
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            einloggen
          </Button>
          <Link href="#" variant="body2">
            Forgot password?
          </Link>
        </Box>
      </Box>
      {error && (
        <Snackbar
          open={error}
          autoHideDuration={3000}
          onClose={() => setError(false)}
        >
          <Alert severity="error">
            <AlertTitle>Fehler beim Login</AlertTitle>
            Der Login ist fehlgeschlagen
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default Login;
