import React, { createContext, useContext, useState } from "react";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";

export interface User {
  email: string;
  vorname: string;
  nachname: string;
  uuid: string;
  role: "administrator" | "gourmetta" | "kronospan";
}

export interface IUseAuthProvider {
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  validate: () => void;
  setUser: (user: User) => void;
  loggedIn: boolean;
  user: User | null;
  validating: boolean;
}

interface LoginResponse {
  email: string;
  vorname: string;
  nachname: string;
  uuid: string;
  token: string;
  role: "administrator" | "gourmetta" | "kronospan";
}

export const AuthContext = createContext({} as IUseAuthProvider);

const useAuthProvider = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [validating, setValidating] = useState<boolean>(true);

  const validate = (): void => {
    const token = localStorage.getItem("token");

    if (!token || typeof token === "undefined" || String(token).length <= 0) {
      localStorage.removeItem("token");
      setLoggedIn(false);
      setValidating(false);
      return;
    }

    const { exp }: JwtPayload = jwtDecode(token);
    if (exp && Date.now() >= exp * 1000) {
      localStorage.removeItem("token");
      setLoggedIn(false);
      setValidating(false);
      return;
    }

    axios
      .get<User>("/user")
      .then((response) => setUser(response.data))
      .catch((error) => console.log(error));
    setLoggedIn(true);
    setValidating(false);
  };

  const login = async (email: string, password: string): Promise<void> =>
    axios
      .post<LoginResponse>("/login", { email, password })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        setUser({
          email: response.data.email,
          uuid: response.data.uuid,
          vorname: response.data.vorname,
          nachname: response.data.nachname,
          role: response.data.role,
        });
        setLoggedIn(true);
      });

  const logout = () => {
    localStorage.removeItem("token");
    setLoggedIn(false);
    setUser(null);
  };

  return {
    loggedIn,
    validating,
    user,
    setUser,
    login,
    logout,
    validate,
  };
};

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const PrivateRoute: React.FC = () => {
  const auth = useAuth();

  if (auth.validating) return <div>Loading</div>;
  if (!auth.loggedIn) return <Navigate to={"/login"} />;
  else return <Outlet />;
};

export const PublicRoute: React.FC = () => {
  const auth = useAuth();

  if (auth.validating) return <div>Loading</div>;
  if (auth.loggedIn) return <Navigate to="/" />;
  else return <Outlet />;
};
