import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Modal,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import DisplayItem from "./Display";
import { useForm } from "react-hook-form";
import axios from "axios";

export interface Display {
  uuid: string;
  number: string;
  title: string;
  created: string;
  updated: string;
  token: string;
  text: string;
  file: MediaFile | null | undefined;
}

interface MediaFile {
  uuid: string;
  name: string;
  size: number;
  created: string;
  updated: string;
}

const Displays = () => {
  const [addModal, setAddModal] = useState<boolean>(false);
  const [displays, setDisplays] = useState<Display[]>([]);
  const [display, setDisplay] = useState<Display | null>(null);
  const [editAllTextsModal, setEditAllTextsModal] = useState<boolean>(false);
  const [files, setFiles] = useState<MediaFile[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const updateDisplayForm = useForm<Display>({
    defaultValues: display ? display : undefined,
  });
  const updateTextForm = useForm<{ text: string }>();
  const createDisplayForm = useForm<Display>();

  const submitDisplay = (data: Display) => {
    axios
      .post<Display>("/displays", data)
      .then((response) => {
        const copy = [...displays];
        copy.push(response.data);
        setDisplays(copy);
        setSuccess("Display erfolgreich gespeichert");
      })
      .catch(() => {
        setError("Fehler beim Speichern des Displays");
      });
  };

  const submit = (data: Display) => {
    axios
      .patch<Display>("/displays", data)
      .then(() => {
        setSuccess("Display erfolgreich gespeichert");
        setDisplay(null);
        const copy = [...displays];
        const index = copy.findIndex((d) => d.uuid === data.uuid);
        copy[index] = data;
        setDisplays(copy);
      })
      .catch(() => {
        setError("Fehler beim Speichern");
      });
  };

  const submitText = (data: { text: string }) => {
    axios
      .post("/displays/text", data)
      .then(() => {
        setEditAllTextsModal(false);
        axios
          .get<Display[]>("/displays")
          .then((response) => {
            setDisplays(response.data);
          })
          .catch(() => setError("Fehler beim Laden der Displays"));
      })
      .catch(() => {});
  };

  useEffect(() => {
    axios.get<Display[]>("/displays").then((response) => {
      setDisplays(response.data);
    });

    axios.get<MediaFile[]>("/files").then((response) => {
      setFiles(response.data);
    });
  }, []);

  const handleDelete = () => {
    setDisplay(null);
    axios
      .get<Display[]>("/displays")
      .then((response) => {
        setDisplays(response.data);
      })
      .catch(() => {
        setError("Fehler beim Laden der Displays");
      });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
      >
        <Button size="large" onClick={() => setAddModal(true)}>
          Display erstellen
        </Button>
        <Button size="large" sx={{ marginLeft: "1rem" }} color="info" onClick={() => setEditAllTextsModal(true)}>
          Texte aller Displays bearbeiten
        </Button>
      </Box>
      <Grid container spacing={2}>
        {displays.map((display, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <DisplayItem
              onSuccess={(message: string) => setSuccess(message)}
              onError={(message: string) => setError(message)}
              onDelete={handleDelete}
              display={display}
              handleDisplayClick={() => {
                setDisplay(display);
                updateDisplayForm.reset(display);
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Modal open={display !== null} onClose={() => setDisplay(null)}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Display bearbeiten
            </Typography>
            {display !== null && (
              <Stack spacing={2} component="form" onSubmit={updateDisplayForm.handleSubmit(submit)}>
                <TextField
                  {...updateDisplayForm.register("title")}
                  required
                  fullWidth
                  type="text"
                  label="Titel (Standort/Position)"
                />
                <TextField
                  {...updateDisplayForm.register("number", { maxLength: 2 })}
                  required
                  fullWidth
                  type="text"
                  label="Nummer"
                />
                <TextField
                  {...updateDisplayForm.register("text")}
                  fullWidth
                  type="text"
                  label="Text"
                  multiline
                  rows={2}
                />
                <FormControl fullWidth>
                  <InputLabel id="file-select-input-label">Datei abspielen</InputLabel>
                  <Select
                    labelId="file-select-input-label"
                    label="Datei abspielen"
                    {...updateDisplayForm.register("file.uuid")}
                    defaultValue={display && display.file ? display.file.uuid : ""}
                  >
                    <MenuItem value="">Nichts</MenuItem>
                    {files.map((file, index) => (
                      <MenuItem key={index} value={file.uuid}>
                        {file.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button type="submit" size="large" fullWidth variant="contained">
                  display speichern
                </Button>
              </Stack>
            )}
          </CardContent>
        </Card>
      </Modal>
      <Modal open={addModal} onClose={() => setAddModal(false)}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Display erstellen
            </Typography>
            <Stack spacing={2} component="form" onSubmit={createDisplayForm.handleSubmit(submitDisplay)}>
              <TextField
                {...createDisplayForm.register("title")}
                required
                fullWidth
                type="text"
                label="Titel (Standort/Position)"
              />
              <TextField
                {...createDisplayForm.register("number", { maxLength: 2 })}
                required
                fullWidth
                type="text"
                label="Nummer"
              />
              <TextField {...createDisplayForm.register("text")} fullWidth type="text" label="Text" />
              <Button type="submit" size="large" fullWidth variant="contained">
                display erstellen
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Modal>
      <Modal open={editAllTextsModal} onClose={() => setEditAllTextsModal(false)}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Texte aller Displays anpassen
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Mit diesem Befehl werden alle anzuzeigenden Texte geichzeitig aktualisiert. Das heißt, jeder Display zeigt
              diesen Text an.
            </Typography>
            <Stack spacing={2} component="form" onSubmit={updateTextForm.handleSubmit(submitText)}>
              <TextField
                margin="normal"
                required
                fullWidth
                {...updateTextForm.register("text", { minLength: 1 })}
                type="text"
                label="Text"
              />
              <Button type="submit" size="large" fullWidth variant="contained">
                Text auf alle Displays speichern
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Modal>
      <Snackbar autoHideDuration={3000} onClose={() => setSuccess(null)} open={success !== null}>
        <Alert severity="success">
          <AlertTitle>Erfolgreich!</AlertTitle>
          {success}
        </Alert>
      </Snackbar>
      <Snackbar autoHideDuration={3000} onClose={() => setError(null)} open={error !== null}>
        <Alert severity="error">
          <AlertTitle>Fehler!</AlertTitle>
          {error}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default Displays;
