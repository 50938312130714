import React, { useEffect } from "react";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Logout } from "@mui/icons-material";
import { Routes, Route, useLocation } from "react-router";
import { PrivateRoute, PublicRoute, useAuth } from "./Auth";
import Login from "./Login";
import Dashboard from "./Dashboard";
import NotFound from "./NotFound";


function App() {
  const location = useLocation();
  const auth = useAuth();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => auth.validate(), []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {auth.loggedIn && (
        <AppBar position="absolute">
          <Toolbar sx={{ pr: "24px" }}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Kronospan Dashboard
            </Typography>
            <Typography component="h2" variant="h6" color="inherit" noWrap>
              {auth.user ? auth.user.vorname + " " + auth.user.nachname : ""}
            </Typography>
            <IconButton onClick={auth.logout} color="inherit">
              <Logout />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Box component="main" sx={{ width: "100%" }}>
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Routes location={location.pathname}>
            <Route path={"/"} element={<PrivateRoute />}>
              <Route path="/" element={<Dashboard />} />
            </Route>
            <Route path="/" element={<PublicRoute />}>
              <Route path={"/login"} element={<Login />} />
              <Route
                path={"/passwort-vergessen"}
                element={<div>Passwort vergessen</div>}
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
      </Box>
    </Box>
  );
}

export default App;
